import $ from 'jquery';

$(document).on('change', '.select-more-select', function (e) {
	showRelatedOptions($(this));
});

function showRelatedOptions(t) {
	const module = t.closest(".select-more"),
		selectedoption = t.val();

	// TODO: make possible to have shared options
	// TODO: use some better attribute than "data-value"

	$(".select-more-options", module).removeClass("is-active").find("input, select").prop("disabled", true);
	$(".select-more-options[data-value='" + selectedoption + "']", module).addClass("is-active").find("input, select").prop('disabled', false);
}

$(".select-more-select").each(function () {
	showRelatedOptions($(this));
});
