// Upload Module
// Dependencies: jQuery, jquery.ui.widget, jquery.iframe-transport, jquery.fileupload
import $ from 'jquery';
require("blueimp-file-upload");
import { RequestVerificationToken } from '/js/utility/request-verification-token';

// TODO: This should be rewritten somehow

// 1. make it easier to set events and settings (like how all other components work)
// 2. try to not have to call initFileUpload for dynamically added inputs
// 3. or why not: not having to add an input at all and instead call this on an existing one and just changing settings?

// indeed
// - the input should be dynamically created (or have a pre-created one somewhere)
// - drop zones should work, actually maybe THEY should be the actual html element this is linked to?
// - make it similar to how $(document).ajax works
// - pass a progress or make a convention for it
// - return a promise
// - automatic failure toast
// <div class="upload" data-url="/upload" on:upload()>

// how can we make that work though???

const globalProgressBar = "#GlobalProgressBar";

const uploadArgs = {
	dropZone: null, // $(this).closest(".upload-dropzone"),
	pasteZone: null,
	//url: $(this).attr('data-url'),
	dataType: 'json',
	singleFileUploads: false,
	// if the upload was in a form, use that, otherwise add default request verification token to the form
	formData: (form) => form.length ? form.serializeArray() : Object.entries(RequestVerificationToken).map(([name, value]) => ({ name, value })),
	submit: function (e, data) {
		$(this).trigger("fileUploadSubmit", data);
	},
	start: function () {
		const t = $(this);
		t.closest('.upload').find('.upload-progress').add(globalProgressBar).last().addClass('is-active')
			.find('.progress-bar')
			.css({
				'width': 0
			});
		t.trigger("fileUploadStarted");
	},
	error: function (e, textStatus, errorMessage) {
		// Get the real element from DOM since the fileInput variable is a clone
		const t = $("#" + this.fileInput[0].id);
		t.closest('.upload').find('.upload-progress').add(globalProgressBar).last()
			.fadeOut(function () {
				$(this)
					.removeClass('is-active')
					.find('.progress-bar')
					.css({ 'width': 0 });
			});
		t.trigger("fileUploadFailed", e.statusText);
	},
	done: function (e, data) {
		const t = $(this);
		t.closest('.upload').find('.upload-progress').add(globalProgressBar).last()
			.fadeOut(function () {
				$(this)
					.removeClass('is-active')
					.find('.progress-bar')
					.css({ 'width': 0 });
			});

		if (data.result.Status === "Error") {
			t.trigger("fileUploadFailed", data.result.Message);
			return;
		}

		t.trigger("fileUploaded", data.result);
	},
	progressall: function (e, data) {
		const progress = parseInt(data.loaded / data.total * 100, 10);
		$(this).closest('.upload').find('.upload-progress').add(globalProgressBar).last()
			.find('.progress-bar').css({ 'width': progress + '%' });
	},
	//dragover: function (e) {
	// todo: implement handler to visualize drop zones
	//},
};

$.fn.initFileUpload = function (elem) { // wtf? why not $('selector').initFileUpload and this.each()??
	elem.each(function () {
		const $this = $(this);
		$this.fileupload(uploadArgs, {
			dropZone: $this.closest(".upload-dropzone"),
		}).prop('disabled', !$.support.fileInput)
			.parent().addClass($.support.fileInput ? undefined : 'disabled');
	});
};

$.fn.upload = function (callback) {
	// simply trigger unregister/register
	const t = $(this);
	t.uploadCallback(callback);
	t.click();
}

$.fn.uploadCallback = function (callback) {
	const t = $(this);
	// the .callback is just a namespace - the event name is fileUploaded
	t.off("fileUploaded.callback");
	t.on("fileUploaded.callback", function (e, data) { callback(data); });

}

$(document).initFileUpload($('.upload-file'));
