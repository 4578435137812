import * as React from "react";
import { createPortal } from "react-dom";
import { createRoot } from "react-dom/client";
import { 
    SpinnerProvider,
    useSpinnerContext
} from "/modules/spinner/general-spinner-context";
import {
    SpinnerContextInitializer,
} from "/modules/spinner/external-access";

const mainSpinnerContainer = document.querySelector("#main-spinner");

export interface SpinnerProps {
    size?: number;
    centered?: boolean;
    color?: string;
}

export interface SpinnerSvgProps {
    size?: number | string;
    centered?: boolean;
    color?: string;
}

export const SpinnerSvg: React.FC<SpinnerSvgProps> = ({ color, size }) => (
    <svg
        className="spinner-svg"
        role="alert"
        aria-busy="true"
        width={size}
        height={size}
        viewBox="0 0 24 24"
    >
        <g>
            <circle 
                cx="12"
                cy="12"
                r="9.5"
                fill="none"
                strokeWidth="3"
                strokeLinecap="round"
                style={{ stroke: color || 'currentColor' }}                
            ></circle>
        </g>
    </svg>
);

export const Spinner: React.FC<SpinnerProps> = ({
                                                    size = 80,
                                                    centered = false,
                                                    color = 'primary',
                                                    ...rest
                                                }) =>(
    <div
        className={`spinner-container ${centered ? 'spinner-centered' : ''}`}
        {...rest}
    >
        <div className="sticky !top-4 !bg-transparent h-fit">
            <SpinnerSvg size={size} color={color}/>
        </div>
    </div>
);


export const InnerSpinner: React.FC = () => {
    const { isVisible } = useSpinnerContext();
    
    if (!isVisible?.internal){
        return null;
    }

    return (
        <div
            className="w-full h-full min-h-[50dvh] absolute inset-0 flex justify-center bg-white dark:lg:bg-zinc-900 z-50"
        >
            <Spinner color="#6365DB"/>
        </div>);
}

export const MainSpinner: React.FC = () => {
    const { isVisible } = useSpinnerContext();

    if (!isVisible?.main){
        return null;
    }
    
    return (
        <div
            className="absolute inset-0 flex justify-center bg-white lg:rounded-lg dark:lg:bg-zinc-900 z-50"
        >
            <Spinner color="#6365DB"/>            
        </div>);
}

export function createInnerSpinner(containerId: string) {
    const innerSpinnerContainer = document.getElementById(containerId);
    const innerSpinner = createPortal(
            <InnerSpinner/>,
            innerSpinnerContainer
    );
    
    createSpinner(innerSpinner);
}

export function createSpinner(children?: React.ReactNode) {
    if (!mainSpinnerContainer){
        return;
    }
    
    createRoot(mainSpinnerContainer).render(
        <SpinnerProvider>
            <SpinnerContextInitializer/>
            <MainSpinner/>
            { children }
        </SpinnerProvider>
    );    
}

createSpinner();