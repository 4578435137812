
const themelistener = 'BroadcastChannel' in window ? new BroadcastChannel('theme_broadcast') : { postMessage() { } };

themelistener.onmessage = ev => {
	loadCss(ev.data)
};

function loadCss(cssurl) {
	const link = document.getElementById("themeCssLink");
	if (link) {
		link.href = cssurl;
	}
}

export function changeTheme(cssurl) {
	loadCss(cssurl);
	themelistener.postMessage(cssurl);
}

