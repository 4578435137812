import $ from 'jquery';

// Masthead Menu toggle

$(document).on('click', '.masthead-menu-toggle', function (e) {

	e.preventDefault();
	const container = $(this).closest('.masthead-menu');
	container.toggleClass('open');

	if (container.hasClass('open')) {
		$(document).one('click', function (e) {
			container.removeClass('open');
		});
	}
});

$(document).on('change', '#masthead-menu-unitselector', function (e) {
	e.preventDefault();
	$(this).closest('.masthead-menu').removeClass('open');
	$(this).closest('form').submit();
});

$(document).on('change', '#masthead-menu-systemselector', function (e) {
	e.preventDefault();
	$(this).closest('.masthead-menu').removeClass('open');
	$(this).closest('form').submit();
});


$(document).on('click', '.masthead-menu-dropdown a', function (e) {
	$(this).closest('.masthead-menu').removeClass('open');
});
