import { createContext, useContext, useState, FC, ReactNode } from 'react';

export interface SpinnerVisibility {
    main: boolean;
    internal: boolean;
}

export const SpinnerContext = createContext<{
    isVisible: SpinnerVisibility;
    setIsVisible: (visibility: SpinnerVisibility | ((prev: SpinnerVisibility) => SpinnerVisibility)) => void;
} | null>(null);

export const SpinnerProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [isVisible, setIsVisible] = useState<SpinnerVisibility>({ main: false, internal: false });

    return (
        <SpinnerContext.Provider value={{ isVisible, setIsVisible }}>
            {children}
        </SpinnerContext.Provider>
    );
};

// Custom hook to use the Spinner Context
export const useSpinnerContext = () => {
    const context = useContext(SpinnerContext);
    
    if (!context) {
        throw new Error("useSpinnerContext must be used within a SpinnerProvider");
    }
    
    return context;
};
