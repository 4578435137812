import $ from 'jquery';
require("../reveal-modal");

$(document).on('click', '.order-detailsform-toggle, .order-detailsform-cancel, .order-detailsform-save', function (e) {
	e.preventDefault();

	const module = $(this).closest('.order');

	module.find('.order-details, .order-details-form').toggleClass('is-hidden');
});


$(document).on('change', '.bundleform-selectbundle', function () {
	const nameField = $(this).closest('.bundleform').find('.bundleform-name');

	if ($(this).val()) {
		nameField.addClass('is-hidden');
	} else {
		nameField
			.removeClass('is-hidden')
			.find('input')
			.focus();

	}
});

$(document).on('click', '.bundleform-cancel', function (e) {
	e.preventDefault();

	const modal = $(this).closest('.reveal-modal');
	modal.trigger('reveal:close');
});

$(document).on('click', '.orderrows-add', function (e) {
	e.preventDefault();
	e.stopPropagation();

	$('#bundle-modal').reveal();
	$('#bundle-modal .bundleform-name input').focus();
});

$(document).on('click', '.add-to-bundle-button', function (e) {
	e.preventDefault();

	$('#bundle-modal').reveal();
	$('#bundle-modal .bundleform-name input').focus();
});
