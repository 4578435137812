import $ from 'jquery';

const filterFunction = function (e) {
	const t = $(this);
	const targetList = $(t.attr("data-list-to-filter"));
	const childrenSelector = t.attr("data-list-children");
	const emptyView = $(t.attr("data-empty-view-id"));
	const filterStr = t.val().toLowerCase();

	targetList.each(function (i, listElement) {
		$(listElement).find(childrenSelector).each(function (j, element) {
			const child = $(element);
			if (child.text().toLowerCase().indexOf(filterStr) !== -1)
				child.removeClass("is-hidden");
			else
				child.addClass("is-hidden");
		});
	});

	emptyView.addClass('is-hidden');
	targetList.removeClass('is-hidden');

	if (emptyView.length) {
		const showingAnyChildren = targetList.find(childrenSelector + ':visible').length;
		if (!showingAnyChildren) {
			emptyView.removeClass('is-hidden');
			targetList.addClass('is-hidden');
		}
	}

	t.trigger("filterTriggered");
};

$(document).on('triggerFilter', '.listfilter', filterFunction);
$(document).on('input', '.listfilter', filterFunction);
$(document).ready(function () {
	$('.listfilter').trigger('triggerFilter');
});
