import $ from 'jquery';

// Alerts

$(document).on('click', '.alert-close', function (e) {
	e.preventDefault();

	$(this).closest('.alert').fadeOut(function () {
		$(this).show();
		$(this).addClass("is-hidden");
	});
});
