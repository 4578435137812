import $ from 'jquery';
import 'jquery-mousewheel'; // used by fixed-header-table
import "fixed-header-table";

// todo: export create and destroy functions?
// actually, maybe fixed-header-table should just be replaced by sticky table headers? it's not working that well anyway is it?

$('table.has-fixed-header').each(function () {
	const table = $(this);
	let height = 300;

	if (table.data('height')) {
		height = table.data('height');
	}

	table.fixedHeaderTable({ height: height });
});
