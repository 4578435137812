import $  from 'jquery';
import Handlebars from 'handlebars';

const templates = {};
const partials = {};

/**
Runs a handlebars template and adds the result to the selected element(s)
@param {HTMLElement|JQuery|string} elem HTMLElement, JQuery object or selector to add a handlebars mustache to.
@param {object} [data] the data to pass to the handlebars template, if omitted, checks data-handlebars-data
@param {string} [templateId] the id of the handlebars template script, if omitted checks data-handlebars-template
*/
export function handlebars(elem, data, templateId) {
	$(elem).each(function () {
		const t = $(this);
		let _data = data,
			_templateId = templateId;

		if (typeof _templateId === "undefined") {
			_templateId = t.data("handlebarsTemplate");
		}
		if (typeof _data === "undefined" || data === null) {
			_data = t.data("handlebarsData");
		}
		t.html(getHtmlFromTemplate(_templateId, _data));
	});
}

/**
Refresh a template
@param {string} templateId the id of the handlebars template
@param {jQuery|string} module the selector-string to the module or an jQuery element referencing the module
@param {jQuery|string} emptyView the selector-string to the empty view or an jQuery element referencing the empty view
@param {object[]} json the data that should be used when refreshing the module using the template
@param {boolean} [emptyModule=false] if true the module will be emptied before refreshing
*/
export function refreshTemplate(templateId, module, emptyView, json, emptyModule) {
	emptyModule = typeof emptyModule !== 'undefined' ? emptyModule : true;

	if (!(templateId in templates)) {
		templates[templateId] = Handlebars.compile($(templateId).html());
	}

	const moduleElement = typeof module === "string" ? $(module) : module;

	if (moduleElement.length !== 1) {
		$.error("Module selector not unique");
	}

	if (emptyModule) {
		moduleElement.empty();
	}

	// the following two statements make this method pretty useless as it requires the json to be an array. -- .length and .each()

	if (emptyView != null) {
		const emptyViewElement = typeof emptyView === "string" ? $(emptyView) : emptyView;
		json.length === 0 ? emptyViewElement.removeClass("is-hidden") : emptyViewElement.addClass("is-hidden");
	}

	$.each(json, function (i, jsonItem) {
		const html = templates[templateId](jsonItem);
		moduleElement.append(html);
	});
}

/**
clear a module
@param {string} moduleId the id of the element to clear (no leading #)
*/
export function clearModule(moduleId) {
	moduleId = "#" + moduleId;
	const module = $(moduleId);
	module.empty();
}

/**
replace an item in a module
@param {string} childSelector the jquery selector of the child(ren) to remove
@param {object} data the data to handlebarsify and replace with
@param {string} [templateId] the id of the template to use, if not specified, use data-handlebars-template - which is probably wrong because this is an item in a list...
*/
export function replaceInModule(childSelector, data, templateId) {
	this.each(function () {
		const t = $(this);
		let _templateId = templateId;

		if (typeof _templateId === "undefined") {
			_templateId = t.data("handlebarsTemplate");
		}
		t.children(childSelector).replaceWith(getHtmlFromTemplate(_templateId, data));
	});
}

/**
delete specified children from another element
@param {string} moduleId the id of the parent element (no leading #)
@param {string} emptyViewId the id of an element to show if the module element has no children left
@param {string} childSelector a selector for the elements to remove
*/
export function deleteFromModule(moduleId, emptyViewId, childSelector) {
	const module = $("#" + moduleId);
	module.children(childSelector).remove();
	$("#" + emptyViewId).toggleClass("is-hidden", module.children().length !== 0);
}

/**
get html from handlebars template
@param {string} templateId the id of the handlebars template script
@param {object} json the object to pass to the handlebars template
@returns {string} html
*/
export function getHtmlFromTemplate(templateId, json) {
	templateId = "#" + templateId;

	if (!(templateId in templates)) {
		// load partials
		const t = $(templateId);
		const partials = t.data("handlebarsPartials");
		if (typeof partials === "string") {
			registerPartial(partials.split(" "));
		}

		templates[templateId] = Handlebars.compile(t.html());
	}

	return templates[templateId](json);
}

/**
register a partial handlebars template
@param {string|string[]} templateId the id of the handlebars template script, or an array of template ids
@param {string} [partialName] the name known to handlebars, if omitted uses the templateId, only used if templateId is not array
*/
export function registerPartial(templateId, partialName) {

	if ($.isArray(templateId)) {
		$.each(templateId, function (index, item) {
			registerSinglePartial(item);
		});
	} else {
		registerSinglePartial(templateId, partialName);
	}
}

function registerSinglePartial(templateId, partialName) {
	if (typeof partialName === "undefined") {
		partialName = templateId;
	}
	templateId = "#" + templateId;
	if (!(templateId in partials)) {
		partials[partialName] = templateId;
		Handlebars.registerPartial(partialName, $(templateId).html());
	}
}

Handlebars.registerHelper('first', function () {
	for (let i = 0; i < arguments.length - 1; i++) { // - 1 because last should be handlebars options var
		if (arguments[i]) {
			return arguments[i];
		}
	}
	return null;
});

Handlebars.registerHelper('unless_blank', function (item, block) {
	return (item && item.replace(/\s/g, "").length) ? block.fn(this) : block.inverse(this);
});

Handlebars.registerHelper('array_empty', function (item, block) {
	return item.length === 0 ? block.fn(this) : block.inverse(this);
});

Handlebars.registerHelper('array_not_empty', function (item, block) {
	return item.length !== 0 ? block.fn(this) : block.inverse(this);
});

Handlebars.registerHelper('array_equals', function (array1, array2, block) {
	const is_identical = (array1.length === array2.length) && array1.every(function (element, index) {
		return element === array2[index];
	});

	return is_identical ? block.fn(this) : block.inverse(this);
});

Handlebars.registerHelper('array_contains_only', function (array, value, block) {
	return (array.length === 1 && array[0] === value) ? block.fn(this) : block.inverse(this);
});

Handlebars.registerHelper('if_cond', function (v1, v2, block) {
	return v1 === v2 ? block.fn(this) : block.inverse(this);
});

Handlebars.registerHelper('if_not_cond', function (v1, v2, block) {
	return v1 !== v2 ? block.fn(this) : block.inverse(this);
});

Handlebars.registerHelper('gt', function (v1, v2, block) {
	return v1 > v2 ? block.fn(this) : block.inverse(this);
});

Handlebars.registerHelper('lt', function (v1, v2, block) {
	return v1 < v2 ? block.fn(this) : block.inverse(this);
});

Handlebars.registerHelper('json', function (context) {
	return JSON.stringify(context);
});

Handlebars.registerHelper('isDisabled', function (context) {
	return context ? 'disabled' : '';
});

Handlebars.registerHelper('isNotDisabled', function (context) {
	return context ? '' : 'disabled';
});

// Auto handlebars

handlebars('.handlebars-auto');