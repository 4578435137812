import $ from 'jquery';

$(document).on('click', 'table.is-clickable tbody tr', function () {
	const tr = $(this),
		table = tr.closest('table'),
		isClickableClass = table.data("isClickableClass"),
		tables = isClickableClass ? $("table." + isClickableClass) : table;

	if (!table.data('multipleSelect')) {
		tables.find('tbody tr.is-selected').removeClass('is-selected');
	}

	if (tr.hasClass('is-selected')) {
		tr.removeClass('is-selected');
		table.trigger('table.rowDeselected', [tr]);
	} else {
		tr.addClass('is-selected');
		table.trigger('table.rowSelected', [tr]);
	}
});


// Sortable
$(document).on("click", "table.is-sortable thead th", function () {
	// sort table according to data-sortvalue or content
	const t = $(this),
		table = t.closest("table");
	let rows = table.find('tbody tr').toArray().sort(comparer(t.index()));

	t.data("asc", !t.data("asc"));
	if (!t.data("asc")) { rows = rows.reverse(); }
	for (let i = 0; i < rows.length; i++) { table.append(rows[i]); }

	table.find('thead .fa').removeClass("fa-sort-up fa-sort-down");
	t.find(".fa").addClass(t.data("asc") ? "fa-sort-up" : "fa-sort-down");
});

function comparer(index) {
	return function (a, b) {
		const valA = getCellValue(a, index), valB = getCellValue(b, index);
		return $.isNumeric(valA) && $.isNumeric(valB) ? valA - valB : valA.toString().localeCompare(valB);
	};
}
function getCellValue(row, index) {
	const cell = $(row).children('td').eq(index),
		sortvalue = cell.data("sortvalue");

	if (typeof sortvalue !== "undefined")
		return sortvalue;
	else
		return cell.text().trim();
}
