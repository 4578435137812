import $ from 'jquery';
import { useState, Fragment } from 'react';
import { createRoot } from "react-dom/client";
import { toDataAttributesObject } from '/js/utility/jsx-helpers';

$(document).on('click', '.breadcrumbs .breadcrumbs-item > a', function (e) {
	e.preventDefault();

	const t = $(this),
		breadcrumbs = t.closest('.breadcrumbs'),
		data = t.closest('.breadcrumbs-item').data(),
		event = $.Event("breadcrumbChanged");

	breadcrumbs.trigger(event, [this.href, data]);
	if (!event.isDefaultPrevented()) {
		window.location = this.href;
	}
});

let initialState = [];
let setBreadCrumbsState = (data) => { initialState = data };

$("#breadcrumbs").each(function () { // there should be only one #breadcrumbs, but hey, simplicity
	const t = $(this),
		data = t.data("items");
	if (data) {
		initBreadCrumbs(this);
		setBreadCrumbsState(data);
	}
});

export function setBreadCrumbs(data) {
	setBreadCrumbsState(data);
}

function initBreadCrumbs(elem) {
	createRoot(elem).render(<BreadCrumbs />);
}

function BreadCrumbs() {
	const [state, setState] = useState(initialState);

	setBreadCrumbsState = setState;

	return (<>{state.map(item => <BreadCrumbItem key={item.ViewLink} data={item} />)}</>);
}

function BreadCrumbItem(props) {
	const item = props.data;
	const className = item.IsCurrent ? "breadcrumbs-item is-current" : "breadcrumbs-item";
	const dataAttributes = toDataAttributesObject(item.Data);
	return (
		<li className={className} {...dataAttributes}><a href={item.ViewLink}>{item.Title}</a></li>
	);
}

