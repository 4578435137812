import $ from 'jquery';

// Drop-down button
$(document).on('click', '.dropdown-toggle', function (e) {
	const t = $(this);

	e.preventDefault();
	e.stopPropagation();

	if (t.attr('disabled') || t.hasClass("disabled")) {
		return;
	}

	const buttonGroup = t.closest('.button-group, .dropdown-button');

	if (!buttonGroup.hasClass('open')) {
		// Close all other dropdowns
		$(".button-group.open, .dropdown-button.open").removeClass("open");


		t.focus();
		$(document).one('click', function () {
			buttonGroup.removeClass('open');
		});
	}

	buttonGroup.toggleClass('open');
});

$(document).on('click', '.dropdown-menu li, .dropdown-menu a', function () {
	$(this).closest('.button-group, .dropdown-button').removeClass('open');
});
