import $ from 'jquery';

const scrollStack = [];

export function setNoScroll() {
	const scrollTop = -($('body').scrollTop() + $('html').scrollTop()); // chrome uses body for scrolltop, but apparently html is the correct one, the other seem to always return zero so just adding them together here...
	if (!scrollStack.length) {
		$('body').addClass('no-scroll').css('top', scrollTop + 'px');
	}
	scrollStack.push(scrollTop);
		
}

export function releaseNoScroll() {
	const scrollTop = scrollStack.pop();
	if (!scrollStack.length) {
		$('body').removeClass('no-scroll').css({'top': ''});
		$('body, html').scrollTop(-scrollTop); // chrome uses body for scrolltop, but apparently html is the correct one
	}
}

// TODO: Don't think these are used
window.noScroll = setNoScroll;
window.releaseNoScroll = releaseNoScroll;