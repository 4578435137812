import $ from 'jquery';
import "/modules/fixed-header-table";

// todo: maybe this should 1) not be in modules, 2) be required from respective page scripts that use it? it's too specific...
// List of orderrows

$(document).on('click', '.bundle-details-close', function (e) {
	const table = $('.bundle-itemtable');
	$('.bundle-details').removeClass('is-active');

	table.find('tbody tr.is-selected').removeClass('is-selected');
	table.fixedHeaderTable('destroy');

});


// Click on checkbox in table row
$(document).on('click', '.bundle-item-label', function (e) {
	e.stopPropagation();
});


// Bundle edit view

$(document).on('click', '.bundle-edit-toggle', function (e) {
	e.preventDefault();

	$('.bundle-edit-view, .bundle-detail-view').toggleClass('is-hidden');

});
