
/**
 * The idea is that this can be used to get a callback when the element is visible.
 * Usable to get height for example, which cannot be done when the element is hidden.
 */
export class VisibilityObserver
{
	/**
	 * Creates a new visibility observer
	 * @param {Function} callback Callback for when the element becomes visible
	 */
	constructor(callback) {
		this._callback = callback;
		this.observer = new IntersectionObserver((entries) => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					//console.info("element is visible", entry.target.id);
					this._callback(entry.target);
					this.unobserve(entry.target);
				} else {
					//console.info("element is not visible", entry.target.id);
				}
			});
		}, {
			root: document.body,
		});
	}

	/**
	 * Observes the element
	 * @param {HTMLElement} element Element to be observed
	 */
	observe(element) {
		this.observer.observe(element);
	}

	/**
	 * Unobserves the element
	 * @param {HTMLElement} element Element to be unobserved
	 */
	unobserve(element) {
		this.observer.unobserve(element);
	}
}