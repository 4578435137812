import $ from 'jquery';

// Tabs
$(document).on('click', '.tabs-tab a', navigateToTab);
$(document).on('navigateToTab', ".tabs-tab a", navigateToTab);

function navigateToTab(e, params) {
	e.preventDefault();

	if (this.href.indexOf('#') === -1) {
		e.preventDefault();
		e.stopPropagation();
		location.href = this.href;
		return;
	}

	const hash = this.href.substring(this.href.indexOf('#')),
		tabs = $(this).closest('.tabs-tabs'),
		tabModule = tabs.closest('.tabs');

	tabs.find('.is-active').removeClass('is-active');
	$(this).closest('.tabs-tab').addClass('is-active');

	tabModule.find('.tabs-content.is-active').removeClass('is-active');
	$(hash).addClass('is-active');

	tabModule.trigger('tabNavigated', [hash]);
}

$(".tabs").on("navigateToFirstErrorTab", function (e) {
	const t = $(this);

	// Don't navigate away from current tab if it has errors
	if (!t.find(".tabs-content.is-active .form-validation-error").length) {
		const tabToShow = $(".form-validation-error", t).first().closest(".tabs-content");
		tabToShow.parent().find("a[href='#" + tabToShow.attr("id") + "']").trigger('navigateToTab');
	}
});
