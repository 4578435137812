import $ from 'jquery';
require("imagesloaded");

$('.preview').each(function () {
	const preview = $(this);

	init(preview);

	preview.on('preview.init', function () {
		init(preview);
	});

	preview.on('preview.resize', function () {
		setImageContainerHeight(preview);
	});

});

$(document).on('click', '.preview-next', function (e) {
	e.preventDefault();

	const container = $(this).closest('.preview');
	const images = $('.preview-image', container);
	const active = $('.flip-active', images);
	let next = active.next();
	if (next.length === 0) {
		next = images.children().first();
		active.toggleClass('flip-active flip-after');
		next.toggleClass('flip-active flip-before');
		$('.flip-before', images).toggleClass('flip-before flip-after');
	} else {
		active.toggleClass('flip-active flip-before');
		next.toggleClass('flip-active flip-after');
	}

	setImageContainerHeight(container);

	// find non-hidden span
	// find next span - or first if none
	// toggle is-hidden on those

	//$('.preview-label span', container).toggleClass('is-hidden');
});

$(document).on('click', '.preview-previous', function (e) {
	e.preventDefault();

	const container = $(this).closest('.preview');
	const images = $('.preview-image', container);
	const active = $('.flip-active', images);
	let next = active.prev();
	if (next.length === 0) {
		next = images.children().last();
		active.toggleClass('flip-active flip-before');
		next.toggleClass('flip-active flip-after');
		$('.flip-after', images).toggleClass('flip-before flip-after');
	} else {
		active.toggleClass('flip-active flip-after');
		next.toggleClass('flip-active flip-before');
	}

	setImageContainerHeight(container);

	// find non-hidden span
	// find next span - or first if none
	// toggle is-hidden on those

	//$('.preview-label span', container).toggleClass('is-hidden');
});

$(window).on('resize', function () { setImageContainerHeight(); });


// Private methods

function init(elem) {
	if (!elem) {
		elem = $('.preview');
	}

	$('.preview-image', elem).imagesLoaded(function () {
		setImageContainerHeight(elem);
	});
}

function setImageContainerHeight(elem) {
	if (!elem) {
		elem = $('.preview');
	}

	if (!elem.length) {
		return;
	}

	const that = $('.preview-image', elem);
	that.height($('.flip-active img', that).height());

	elem.trigger('preview.resized');
}
