const lightbox = require("lightbox2");

// Lightbox2 settings
lightbox.option({
	"albumLabel": "%1 / %2",
	"fadeDuration": 300,
	"imageFadeDuration": 300,
	"resizeDuration": 300,
});

export default lightbox;