import $ from 'jquery';

// TODO: Some unused code in here...

const templateLi = $('.productsearch .productlist-item:first').clone();

$(document).on('click', '.productsearch-resulttoggle-show', function (e) {
	e.preventDefault();
	const that = $(this),
		module = that.closest('.productsearch'),
		resultList = $('.productsearch-resultlist', module);

	toggleResultList(this, false);
});

$(document).on('click', '.productsearch-resulttoggle-hide', function (e) {
	e.preventDefault();
	toggleResultList(this, true);
});

function toggleResultList(elem, show) {
	const that = $(elem),
		module = that.closest('.productsearch');

	module.toggleClass('hide-list', show);
}

$(document).on('keyup search', '.productsearch-input', function (e) {
	//console.log(e.keyCode);

	const that = $(this),
		module = that.closest('.productsearch'),
		resultContainer = $('.productsearch-result', module),
		valLength = that.val().length,
		tempUl = $('<ul />'),
		productList = $('.productlist-list', module);

	resultContainer.toggleClass('is-active', valLength > 0);

	let noOfItems = 0;

	switch (valLength) {
		case 0:
			noOfItems = 0;
			break;
		case 1:
			noOfItems = 30;
			break;
		case 2:
			noOfItems = 25;
			break;
		case 3:
			noOfItems = 18;
			break;
		case 4:
			noOfItems = 5;
			break;
		case 5:
			noOfItems = 1;
			break;
		default:
			noOfItems = 0;
	}

	$('.productsearch-resultcount', module).text(noOfItems);

	for (let i = 0; i < noOfItems; i++) {
		tempUl.append(templateLi.clone());
	}
	productList.html(tempUl.html());

});
