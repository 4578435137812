import $ from "jquery";

// TODO: Export toastOk, toastInfo, toastError functions?

const globalToastContainer = $("#GlobalToastContainer");

if (globalToastContainer.data("toastMessage")) {
	// Auto toast are always "ok" for some reason...
	showToast(globalToastContainer.data("toastMessage"), globalToastContainer.data("toastMessageType"));
}

/**
 * Show an error toast message
 * @param {string} message The error message
 * @param {number} [duration] Duration in ms, default 3000.
 */
export function showToastError(message, duration)
{
	showToast(message, "error", duration);
}

/**
 * Show a message with the specified style
 * @param {string} message The toast message.
 * @param {string} [style] Toast style "ok", "error", or "info". Default "ok".
 * @param {number} [duration] Duration in ms, default 3000.
 */
export function showToast (message, style, duration) {
	// eslint-disable-next-line eqeqeq
	if (!style)
		style = "ok";
	// eslint-disable-next-line eqeqeq
	if (!duration)
		duration = 3000;

	let type = "";
	if (style === "info") type = "toast-notification";
	else if (style === "ok") type = "toast-success";
	else if (style === "error") type = "toast-error";

	const toast = createToast(message, type);

	toast.fadeIn(200, function () {
		setTimeout(function () {
			toast.fadeOut(400, function () {
				toast.remove();
			});
		}, duration);
	});
}

function createToast(message, type) {
	const toast = $('<div class="toast">')
		.addClass(type);

	const msg = $('<span class="toast-message">')
		.text(message);

	toast.append(msg);

	// add to dom
	globalToastContainer.append(toast);
	return toast;
}
