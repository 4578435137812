
/**
 * Delay/debounce a function a number of milliseconds.
 * If called again during the duration of the timeout, the timeout will be reset.
 * 
 * usage:
 * var delay = new Delay(1000);
 * delay.then(() => whatever());
 * */
class Delay
{
	constructor(timeout)
	{
		this.timer = 0;
		this.ms = timeout;
	}

	/** Returns a promise that resolves after the set timeout */
	then(callback) {
		clearTimeout(this.timer);
		return new Promise((resolve, reject) => this.timer = setTimeout(resolve, this.ms)).then(callback);
	}
}

export default Delay;
