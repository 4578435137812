import $ from 'jquery';
import { loader } from '/modules/loader';
import { setNoScroll, releaseNoScroll } from '/modules/no-scroll';

let viewportElem;

const flipbookOptions = {
	//page: 0,
	//url: "", // (for iframe)
};

$(document).on('click', '[data-flipbook-url]', function (event) {
	event.preventDefault();
	const flipbookUrl = $(this).data('flipbookUrl');
	openFlipbook({ url: flipbookUrl });
});


export default function openFlipbook(options) {
	// New version - adds everything, no need for model!
	options = $.extend({}, flipbookOptions, options);
	viewportElem = $('<div class="flipbook-viewport">');
	const closeButton = $('<div class="flipbook-close-iframe"><i class="fa fa-close"></i></div>');
	viewportElem.append(closeButton);
	const iframe = $("<iframe class=\"flipbook-iframe\" allowfullscreen>");
	loader.show();
	iframe
		.on("load", () => {
			loader.hide();
			iframe.get(0).contentWindow.focus();
		})
		.prop("src", options.url);
	viewportElem.append(iframe);
	setNoScroll();
	$('body').append(viewportElem);
	setUserScalable(false);
	closeButton.on('click', destroy);
	window.addEventListener("message", postMessageEventListener);
}

function postMessageEventListener(e) {
	if (e.data === "closeFlipbook") {
		destroy();
	}
}


function destroy() {
	viewportElem.remove();
	releaseNoScroll();
	setUserScalable(true);
	window.removeEventListener("message", postMessageEventListener);
}

function setUserScalable(value) {
	if (!value) {
		$("meta[name=viewport]").attr("content", function (i, content) { return "user-scalable=no, " + content; });
		$("body").css("touch-action", "manipulation"); // supposedly disables double-click-to-zoom on safari mobile
	}
	else {
		$("meta[name=viewport]").attr("content", function (i, content) { return content.replace("user-scalable=no, ", ""); });
		$("body").css("touch-action", "unset");
	}
}
