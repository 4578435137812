import $ from 'jquery';

function getPlayer(t) {
	let player = t.closest(".media-player").find(".media-element");

	if (!player.data("initialized")) {
		player.data("initialized", true);

		if (player.get(0).error)
			error.call(player.get(0));

		player
			.on("timeupdate", progressUpdate)
			.on("waiting", waiting)
			.on("playing", playing)
			.on("pause", pause)
			.on("error", error)
			;
	}

	return player.get(0);
}

// Play event
$(document).on("click", ".media-player .media-play-button", function () {
	let t = $(this),
		player = getPlayer(t);

	if (player.error) return;

	if (player.paused || player.ended)
		player.play();
	else
		player.pause();
});

// Pause
$(document).on("click", ".media-player .media-stop-button", function () {
	let t = $(this),
		player = getPlayer(t),
		progress = t.closest(".media-player").find("progress").get(0);

	if (player.error) return;

	player.pause();
	player.currentTime = 0;
	progress.value = 0;
});

// Seek
$(document).on("click", ".media-player .media-progress progress", function (e) {
	let t = $(this),
		player = getPlayer(t);

	if (player.error) return;

	player.currentTime = (e.offsetX / this.offsetWidth) * player.duration;
});

function waiting() {
	$(this).closest(".media-player").attr("media-state", "waiting")
}

function playing() {
	$(this).closest(".media-player").attr("media-state", "playing");
}

function pause() {
	$(this).closest(".media-player").attr("media-state", "paused");
}

function error(e) {
	let module = $(this).closest(".media-player");
	module.attr("media-state", "error");
	console.log("Media load error " + this.error.code + ": " + this.error.message);
}

function progressUpdate() {
	let t = $(this),
		module = t.closest(".media-player"),
		progress = module.find("progress").get(0);

	progress.max = this.duration;
	progress.value = this.currentTime;
}
