import $ from 'jquery';

$(document).on('click', '.listbuilder-add', function (e) {
	e.preventDefault();
	addItems(this);
});

$(document).on('dblclick', '.listbuilder-available-items', function (e) {
	addItems(this);
});

$(document).on('click', '.listbuilder-remove', function (e) {
	e.preventDefault();
	removeItems(this);
});

$(document).on('dblclick', '.listbuilder-assigned-items', function (e) {
	removeItems(this);
});

$(document).on('click', '.listbuilder-setdefault', function (e) {
	e.preventDefault();
	var lb = $(this).closest('.listbuilder');
	var selected = lb.find('.listbuilder-assigned-items option:selected');
	if (selected.hasClass('is-default'))
		clearDefaultItem(lb);
	else
		setDefaultItem(lb, selected.val());
});

function addItems(obj) {
	var container = $(obj).closest('.listbuilder'),
		selectedItems = $('.listbuilder-available-items option:selected', container),
		assignedItemsControl = $('.listbuilder-assigned-items', container),
		addedValues = [];

	if (!selectedItems.length) {
		return;
	}

	assignedItemsControl.append(selectedItems.removeAttr('selected'));

	selectedItems.each(function () {
		addedValues.push($(this).val());
	});

	container.trigger('itemsAdded', [addedValues]);
}

function removeItems(obj) {
	var container = $(obj).closest('.listbuilder'),
		selectedItems = $('.listbuilder-assigned-items option:selected', container),
		availableItemsControl = $('.listbuilder-available-items', container),
		removedItems = [];

	if (!selectedItems.length) {
		return;
	}

	// Remove default if set

	availableItemsControl.append(selectedItems.removeAttr('selected'));

	selectedItems.each(function () {
		removedItems.push($(this).val());
	});

	container.trigger('itemsRemoved', [removedItems]);
}

function setItemAsDefault(obj) {
	var container = $(obj).closest('.listbuilder'),
		defaultItem = $('.listbuilder-assigned-items option.is-default', container),
		selectedItem = $('.listbuilder-assigned-items option:selected:first', container);

	if (!selectedItem.length) {
		return;
	}

	defaultItem.removeClass('is-default').text(removeDefaultText(defaultItem.text()));
	selectedItem.addClass('is-default').text(setDefaultText(selectedItem.text()));

	container.trigger('itemSetToDefault', [selectedItem.val()]);
}

function setDefaultItem(lb, value) {
	clearDefaultItem(lb);
	var x = lb.find('.listbuilder-assigned-items option[value=' + value + ']');
	x.addClass('is-default').text(setDefaultText(x.text()));
	lb.find('.listbuilder-default-value').val(value);
}

function clearDefaultItem(lb) {
	lb.find('.listbuilder-assigned-items option.is-default').each(function (i, x) { $(x).text(removeDefaultText($(x).text())); });
	lb.find('.listbuilder-assigned-items option').removeClass('is-default');
	lb.find('.listbuilder-default-value').val("");
}

function setDefaultText(text) {
	return text + ' (default)';
}

function removeDefaultText(text) {
	return text.substr(0, text.indexOf('(default)'));
}

// Wire all listbuilders to select all elements on submit so they get included in the post
$(".listbuilder select.listbuilder-assigned-items").each(function (i, elem) {
	$(elem).closest("form").submit(function (event) {
		$(elem).find("option").prop("selected", "selected");
	});
});
