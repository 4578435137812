import $ from 'jquery';
import { refreshTemplate } from "../handlebars";
import { ajax } from "/js/utility/ajax";
import { createConfirm } from '../confirm';

$(document).on('click', '.address-select', function (e) {
	e.preventDefault();

	const that = $(this),
		module = that.closest('.address-module');

	module
		.find('.address-select')
		.closest('li')
		.removeClass('is-selected')

	that
		.closest('li')
		.addClass('is-selected');

	SetEditMode(module, false);

	module
		.find('.address-views')
		.removeClass('is-hidden');

	const addr = JSON.parse(that.attr("data-address-json"));

	setSelectedAddress(module, that.data("addressid"), addr);
	module.find('.address-edittoggle').removeClass("is-hidden"); // in case it was hidden before
});

function SetEditMode(module, editmode, newmode) {
	module
		.toggleClass("is-editmode", !!editmode)
		.toggleClass("is-newmode", !!newmode)
		.removeClass("highlight-invalid");

	module.find(".address-required").prop("required", !!editmode);
	module.find(".address-unselected").prop("required", !editmode && module.data("required"));
}

function setSelectedAddress(module, id, address) {
	refreshTemplate("#addresses-address-template", "#" + module.attr("id") + " .address-view-address", "", [address]);
	setEditAddress(module, address);
	module.find(".selectedaddressid").val(id);
}

function setEditAddress(module, addr) {
	module.find(".address-edit #EditAddressAddressId").val(addr.AddressId);
	module.find(".address-edit #UnitForAddress").val(addr.UnitId);
	module.find(".address-edit #isDefaultAddress").prop("checked", addr.IsDefaultForUnit);
	module.find(".address-edit #name").val(addr.Title);
	module.find(".address-edit #ContactPerson").val(addr.ContactPerson);
	module.find(".address-edit #ContactPhone").val(addr.ContactPhone);
	module.find(".address-edit #CompanyName").val(addr.CompanyName);
	module.find(".address-edit #StreetAddress").val(addr.StreetAddress);
	module.find(".address-edit #Zip").val(addr.Zip);
	module.find(".address-edit #City").val(addr.City);
	module.find(".address-edit #State").val(addr.State);
	module.find(".address-edit #Country").val(addr.Country);
}

$(document).on('click', '.address-newtoggle', function (e) {
	e.preventDefault();

	const module = $(this).closest('.address-module');

	// Get json from selected dropdown
	// Set fields
	setEditAddress(module, {
		AddressId: 0,
		UnitId: module.attr("data-default-unitId"),
		IsDefaultForUnit: false,
		Title: "",
		ContactPerson: "",
		ContactPhone: "",
		CompanyName: "",
		StreetAddress: "",
		Zip: "",
		City: "",
		State: "",
		Country: "",
	});

	SetEditMode(module, true, true);
});

$(document).on('click', '.address-edittoggle', function (e) {
	e.preventDefault();
	const module = $(this).closest('.address-module');
	SetEditMode(module, true);
});

$(document).on('click', '.address-editcancel', function (e) {
	e.preventDefault();
	const module = $(this).closest('.address-module');
	SetEditMode(module, false);
	module.find(".address-dropdown li.is-selected a").trigger('click');
});

function RemoveAddressFromList(module, addressId) {
	const listItems = module.find(".address-dropdown .dropdown-address a");
	for (let i = 0; i < listItems.length; i++) {
		// eslint-disable-next-line eqeqeq
		if ($(listItems[i]).attr("data-addressId") == addressId) {
			$(listItems[i]).parent().remove();
			if (listItems.length === 1) {
				module.find('.address-edittoggle, .address-selectdropdown').addClass("is-hidden");
			}
		}
	}
}

$(document).on('click', '.address-save', function (e) {
	e.preventDefault();

	const module = $(this).closest('.address-module');

	// todo: validate fields before saving

	if (module.find(":invalid").length) {
		module.addClass("highlight-invalid");
		return;
	}

	const addr = {};
	addr.Type = module.attr("data-address-type");
	addr.AddressId = module.find(".address-edit #EditAddressAddressId").val();
	addr.UnitId = module.find(".address-edit #UnitForAddress").val();
	addr.IsDefaultForUnit = module.find(".address-edit #isDefaultAddress").prop("checked");
	addr.Title = module.find(".address-edit #name").val();
	addr.ContactPerson = module.find(".address-edit #ContactPerson").val();
	addr.ContactPhone = module.find(".address-edit #ContactPhone").val();
	addr.CompanyName = module.find(".address-edit #CompanyName").val();
	addr.StreetAddress = module.find(".address-edit #StreetAddress").val();
	addr.Zip = module.find(".address-edit #Zip").val();
	addr.City = module.find(".address-edit #City").val();
	addr.State = module.find(".address-edit #State").val();
	addr.Country = module.find(".address-edit #Country").val();

	const url = module.attr("data-save-url");

	ajax({
		url: url,
		data: addr
	}).then(data => {
		SetEditMode(module, false);
		const addresses = data.Object;
		const addressModuleId = module.attr("id");
		$("#" + addressModuleId + " .address-dropdown").empty();
		updateAddressDropdown(module, addresses);
		module.find('.address-edittoggle').removeClass("is-hidden"); // in case it was hidden before
		$.each(addresses, function (i, value) {
			if (value.Selected) {
				const savedaddr = JSON.parse(value.Json);
				refreshTemplate("#addresses-address-template", "#" + addressModuleId + " .address-view-address", "", [savedaddr]);
				module.find(".selectedaddressid").val(value.AddressId);
				setEditAddress(module, savedaddr);
			}
		});
	});
});

$(document).on('click', '.address-delete', function (e) {
	e.preventDefault();

	const module = $(this).closest('.address-module'),
		url = module.attr("data-delete-url"),
		addressId = module.find(".address-edit #EditAddressAddressId").val(),
		addressType = module.attr("data-address-type");

	createConfirm(this)
		.then(() => ajax({
			url: url,
			data: { addressId: addressId, type: addressType },
		}))
		.then(() => {
			RemoveAddressFromList(module, addressId);
			setSelectedAddress(module, null, {
				UnitId: module.attr("data-default-unitId"),
			});
			module.find(".address-editcancel").trigger('click');
			module.find(".address-dropdown li.is-defaultaddress a").trigger('click');
		});
});

// Initialize modules after we've attached all handlers

$(".address-module").each(function () {
	const t = $(this),
		addresses = t.data("addresses");

	updateAddressDropdown(t, addresses);
	t.find(".address-dropdown .is-selected .address-select").trigger('click');
});

function updateAddressDropdown(module, addresses) {
	refreshTemplate("#addresses-address-dropdown-template", module.find(".address-dropdown"), "", addresses, false);
	module.find(".address-selectdropdown").toggleClass("is-hidden", !addresses.length)
}