import $ from 'jquery';
import { VisibilityObserver } from '/js/utility/VisibilityObserver';


/**
 * Adds support for auto-height textareas using the class min-height
 * @module modules/behavior/textarea
 */


//! Issues:
// - resizable has no purpose really - it will be reverted as soon as the values is changed
// - FF shows too much height for one-row values for some reason
// - IE doesn't show correct height for textareas that is originally hidden.

const visibilityObserver = new VisibilityObserver(setTextAreaHeight);

/**
 * Sets height when/if the element is visible
 * @param {HTMLElement} elem
 */
function trySetTextAreaHeight(elem) {
	//console.info("trying to set height", elem.id);
	visibilityObserver.observe(elem);
}

/**
 * Set the height immediately
 * @param {HTMLElement} elem
 */
function setTextAreaHeight(elem) {
	//console.info("setting height", elem.id);
	elem.rows = 1; // need to set rows to 1, otherwise it will always have a height of two rows...
	elem.style.height = 'auto'; // needed for ie11 to remove height
	elem.style.height = (elem.scrollHeight + 2) + 'px'; // the 2px are added so that the scrollbar won't show up
}

// TODO: make a utility module out of this, to fire a callback when a tag is added?
/**
 * MutationObserver used to detect newly added textareas.
 */
const mutationObserver = new MutationObserver(function (mutations, observer) {
	mutations.forEach(mutation => {
		if (mutation.type === "childList") {
			mutation.addedNodes.forEach(node => {
				if (node.nodeName === "TEXTAREA" && node.classList.contains("min-height")) {
					//console.info("detected addition", node.id);
					trySetTextAreaHeight(node);
				} else if (node.nodeType === 1) {
					node.querySelectorAll("textarea.min-height").forEach(child => {
						//console.info("detected addition in subtree", child.id);
						trySetTextAreaHeight(child);
					});
				}

			});
		}
	});
});

mutationObserver.observe(document, {
	childList: true,
	subtree: true,
});

// Test, move this elsewhere
// This shouldn't reallyt be required anymore then?
$(document).on("input", "textarea.min-height", function () {
	//console.info("input event fired", this.id);
	setTextAreaHeight(this);
});

$("textarea.min-height").each((i, elem) => trySetTextAreaHeight(elem));

customInputSetter();

/**
 * Defines a custom setter on textareas, that calls for height to be set as well.
 */
function customInputSetter() {

	const descriptor = Object.getOwnPropertyDescriptor(HTMLTextAreaElement.prototype, "value");
	const originalSet = descriptor.set;

	// define our own setter
	descriptor.set = function (val) {
		//console.info("textarea.value set", this.id);
		originalSet.apply(this, arguments);
		trySetTextAreaHeight(this);
	}

	Object.defineProperty(HTMLTextAreaElement.prototype, "value", descriptor);
}