import $ from 'jquery';
import { handlebars } from '../handlebars';

function getPriceTable() {
	const pagecounts = $(".price-table").data("pagecounts");
	return {
		PageCounts: pagecounts,
		Quantities: $(".price-table tbody tr:not(:last)").map(function () {
			return {
				Quantity: $(this).find(".price-quantity").val(),
				Label: $(this).find(".price-label").val(),
				PageCountPrices: $(this).find(".price-pagecountprice").map(function (i) {
					return {
						PageCount: pagecounts[i],
						Price: $(this).val(),
					};
				}).get(),
			};
		}).get(),
	};
}


$(".price-table-container").closest("form").on("submit", function (e) {

	// todo: validate price table
	// - no duplicate quantities
	// - no empty prices if quantity is filled out
	// show first tab with errors

	const pricetable = getPriceTable();
	const json = JSON.stringify(pricetable);
	$("#PriceTableJson").val(json);

});

$(".price-table-container").on("pageCountChange", function (e, pagecounts) {

	// TODO: possibly delay this a bit so all pagecount changes are made first, or even only do it when a switch to that tab is made?

	const pricetable = getPriceTable();

	// TODO: it would be nice to store away all of the once entered page counts so we can get them back if we delete one by mistake...

	// replace pagecountprices with a new array, new pagecount, and price from old
	$.each(pricetable.Quantities, function (i, v) {
		v.PageCountPrices = pagecounts.map(function (c) {
			const oldindex = pricetable.PageCounts.indexOf(c);
			return {
				PageCount: c,
				Price: oldindex === -1 ? "" : v.PageCountPrices[oldindex].Price,
			};
		});
	});
	pricetable.PageCounts = pagecounts;

	handlebars(".price-table-container", pricetable);


});

// add rows automatically
$(".price-table-container").on("keydown", ".price-table tbody tr:last input", function (e) { // this should ideally be onbeforechange
	const row = $(this).closest("tr");
	const clone = row.clone();
	clone.insertAfter(row);
	clone.find("input").val("");
});

$(".price-table-container").on("change", ".price-table tbody input.price-quantity:not(:last)", function (e) {
	const t = $(this),
		label = t.closest('tr').find(".price-label");
	label.attr("placeholder", t.val() + label.data("uom"));
});

$(".price-table-container")
	.on("focusin", ".price-table tbody input.price-label:not(:last)", function (e) {
		const t = $(this);
		if (t.val() === "")
			t.val(t.attr("placeholder"));
	})
	.on("focusout", ".price-table tbody input.price-label:not(:last)", function (e) {
		const t = $(this);
		if (t.val() === t.attr("placeholder"))
			t.val("");
	});



$(".price-table-container").on("focusout", ".price-table tbody input:not(:last)", function (e) { // this should be on change instead

	const table = $(".price-table tbody tr:not(:last)");

	// only do below if we're in .price-quantity
	if ($(this).hasClass("price-quantity")) {
		// TODO: validate table rows
		// TODO: mark any duplicate or empty quantities
		// TODO: if no errors, sort according to quantity

		//var q = $(this).val();
		//if (q !== '') {
		//	var unique = true;
		//	var element = this;
		//	table.find(".price-quantity").each(function () {
		//		if (element !== this && q === $(this).val()) {
		//			unique = false;
		//			return false;
		//		}
		//	});
		//	$(this).closest('tr').toggleClass("error", !unique);
		//}

		table
			.map(function () {
				return {
					key: $(this).find(".price-quantity").val(),
					value: this,
				};
			})
			.sort(function (a, b) {
				return a.key - b.key;
			})
			.map(function () { return this.value; })
			.detach()
			.prependTo($(".price-table tbody"));

	}

	// remove empty
	table.each(function () {
		const t = $(this);
		if (t.find("input").filter(function () { return $(this).val(); }).length === 0) {
			t.remove();
		}
	});
});
