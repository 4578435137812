// plugins, settings for jquery.validation
import $ from 'jquery';
require("jquery-validation");
require("jquery-validation-unobtrusive");
import { showToast } from "./toast";

// BEGIN validation for multiple e-mail addresses

$.validator.addMethod(
	"multiemail",
	function (value, element) {
		if (this.optional(element)) // return true on optional element 
			return true;
		const emails = getEmailAddressesArray(value);
		let valid = true;
		for (const i in emails) {
			value = emails[i];
			valid = valid &&
				$.validator.methods.email.call(this, $.trim(value), element);
		}
		return valid;
	},

	$.validator.messages.multiemail
);
$.validator.unobtrusive.adapters.addBool("multiemail");

// cleanup and separate with ;
$('[data-val-multiemail]').on("blur", function () {
	const t = $(this);
	t.val(getEmailAddressesArray(t.val()).join("; "));
});

// get array of possible e-mail addresses
function getEmailAddressesArray(value) {
	return value.split(/[;,\s]+/).filter(function (e) { return e !== ""; }); // split element by ',', ';', ' ' and clean empty
}

// END validation for multiple e-mail addresses

// Checkbox required
$.validator.unobtrusive.adapters.addBool("chkrequired", "required");

// Check that a list has options - Special for ListBuilder
$.validator.addMethod("selecthasoptionscheck", function (value, element, parameters) {
	return element.options.length > 0;
}, 'Select has no options');

$.validator.unobtrusive.adapters.addBool("selecthasoptionscheck", "selecthasoptionscheck");

// exists check
$.validator.addMethod("exists", function (value, element, parameters) {
	const t = $(element);
	return !!t.closest('form').find("[name=" + t.data("valExists") + "]").length;
}, 'Select has no options');

$.validator.unobtrusive.adapters.addBool("exists", "exists");

// CustomValidation on client side
$.validator.addMethod("customvalidation", function (value, element, parameters) {
	const fn = $(element).data("CustomValidation");
	if (typeof fn === "function") {
		return fn.call(element, value, element, parameters);
	}
	return true; // no custom validation handler found
}, $.validator.messages.custom);

$.validator.unobtrusive.adapters.addBool("custom", "customvalidation");

// Set defaults for validation
$.validator.setDefaults({
	ignore: ".ignore-validation",
	highlight: function (element, errorClass) {
		const formField = $(element).parent();
		formField.addClass("form-validation-error");
	},
	unhighlight: function (element, errorClass) {
		const formField = $(element).parent();
		formField.removeClass("form-validation-error");
	}
});

$.validator.unobtrusive.options = {
	invalidHandler: function () {
		// if this event seems to be not firing, it might because the button is pushed out of the way by the focusout event showing errors at field:
		// https://github.com/jquery-validation/jquery-validation/issues/1811
		showToast($("#GlobalToastContainer").data("defaultFormValidationErrorMessage"), "error");
		$(".tabs").trigger("navigateToFirstErrorTab");
	},
};
