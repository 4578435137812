import $ from 'jquery';

// Preventing default action on disabled links
$(document).on('click', '.disabled', function (e) {
	e.preventDefault();
	e.stopPropagation();
});

$(document).on('click', '.disable-after-clicking', function () {
	const t = $(this);
	setTimeout(() => t.prop("disabled", true), 0);
});
