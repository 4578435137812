import $ from 'jquery';
require("imagesloaded");

// Check if rotation is enabled

let hasRotatableModule = false;

$('.imagegrid').each(function () {
	const imageGrid = $(this);

	imageGrid.bind('init', function () {
		initializeImageGrid(imageGrid);
	});

	if (imageGrid.hasClass('is-rotatable')) {
		initializeImageGrid(imageGrid);
	}

	hasRotatableModule = true;
});

if (hasRotatableModule) {

	$(document).off('click.imagegrid-rotate-all-next').on('click.imagegrid-rotate-all-next', '.imagegrid-rotate-next', function (e) {
		e.preventDefault();
		$('.imagegrid-item').trigger('rotate-next');
	});
	$(document).off('click.imagegrid-rotate-all-previous').on('click.imagegrid-rotate-all-previous', '.imagegrid-rotate-previous', function (e) {
		e.preventDefault();
		$('.imagegrid-item').trigger('rotate-previous');
	});
}

function initializeImageGrid(imageGrid) {

	// Set the height of the imagecontainer when image is loaded
	//imageGrid.imagesLoaded(function () {
	//	setImageContainerHeight(imageGrid);
	//});

	// Set the height of the imagecontainer when window is resized
	$(window).on('resize', function () {
		setImageContainerHeight(imageGrid);
	});

	$('.imagegrid-item', imageGrid).each(function () {
		var item = $(this);

		item.imagesLoaded(function () {
			setTimeout(function () {
				setImageContainerHeight(item);
			}, 10);

		});
	});

	$(imageGrid).off('click.imagegrid-zoom').on('click.imagegrid-zoom', '.imagegrid-zoom', function (e) {
		e.preventDefault();
		const container = $(this).closest('.imagegrid-item').find('.imagegrid-image');
		$(".flip-active img", container).trigger('click');
	});

	$(imageGrid).off('click.rotate-next').on('click.rotate-next', '.imagegrid-rotate', function (e) {
		$(this).trigger("rotate-next");
	});

	$(imageGrid).off('rotate-next').on('rotate-next', '.imagegrid-item', function (e) {
		e.preventDefault();
		const container = $(this);
		const images = $('.imagegrid-image', container);
		const active = $('.flip-active', images);
		let next = active.next();
		if (next.length === 0) {
			next = images.children().first();
			active.toggleClass('flip-active flip-after');
			next.toggleClass('flip-active flip-before');
			$('.flip-before', images).toggleClass('flip-before flip-after');
		} else {
			active.toggleClass('flip-active flip-before');
			next.toggleClass('flip-active flip-after');
		}
		setImageContainerHeight(container);
	});

	$(imageGrid).off('rotate-previous').on('rotate-previous', '.imagegrid-item', function (e) {
		e.preventDefault();
		const container = $(this);
		const images = $('.imagegrid-image', container);
		const active = $('.flip-active', images);
		let next = active.prev();
		if (next.length === 0) {
			next = images.children().last();
			active.toggleClass('flip-active flip-before');
			next.toggleClass('flip-active flip-after');
			$('.flip-after', images).toggleClass('flip-before flip-after');
		} else {
			active.toggleClass('flip-active flip-after');
			next.toggleClass('flip-active flip-before');
		}
		setImageContainerHeight(container);
	});
}

function setImageContainerHeight(elem) {
	const that = $('.imagegrid-image', elem);
	let img = $('.flip-active img', that);
	if (img.length === 0) {
		img = $('img', that);
	}
	const height = img.height();
	that.height(height);
}
