import $ from 'jquery';

$(document).on('click', '.checkboxlist-toggle', function (e) {
	e.preventDefault();
	const container = $(this).closest('.checkboxlist-container'),
		checkboxes = $('input[type=checkbox]', container);
	let isChecked = !container.data('is-checked');

	// If the number of checked items is more than half of the total number of checkboxes, start
	// with unchecking all checkboxes
	if (isChecked && $('input[type=checkbox]:checked', container).length > checkboxes.length / 2) {
		isChecked = false;
	}

	// Note: This may seem like an odd way to do it, but just setting the attribute 
	// doesn't get the job done properly. 
	checkboxes.each(function () {
		if ($(this).is(':checked') !== isChecked) {
			$(this).trigger('click');
		}
	});

	container.data('is-checked', isChecked);
});

$(document).on('click', '.checkboxlist-enable-edit', function (e) {
	e.preventDefault();
	enableEditMode(this);
	$(this).closest('.checkboxlist').trigger('editEnabled');
});

$(document).on('click', '.checkboxlist-disable-edit', function (e) {
	e.preventDefault();
	disableEditMode(this);
	$(this).closest('.checkboxlist').trigger('editDisabled');
});


function enableEditMode(obj) {
	const that = $(obj),
		container = that.closest('.checkboxlist');

	that.addClass('is-hidden');

	container
		.addClass('is-editable')
		.find('.checkboxlist-disable-edit')
		.removeClass('is-hidden');
}

function disableEditMode(obj) {
	const that = $(obj),
		container = that.closest('.checkboxlist');

	that.addClass('is-hidden');

	container
		.removeClass('is-editable')
		.find('.checkboxlist-enable-edit').removeClass('is-hidden');
}

$(document).on('change', '.checkboxlist input[type=checkbox]', function (e) {
	const checkbox = $(this);
	checkbox.closest('.checkboxlist').trigger('checkboxChanged', [checkbox.attr('name'), checkbox.val()]);
});
