import $ from 'jquery';
import { ajax } from "/js/utility/ajax";

$(document).on('click', '.favorite-toggle', function (e) {
	e.preventDefault();
	e.stopPropagation();

	const elem = $(this).closest(".favorite");
	const targetId = elem.data("targetid");
	const targetType = elem.data("targettype");

	ajax({
		url: "/InSys/v2/Favorites/Toggle", // TODO: Url in script
		data: {
			TargetId: targetId,
			TargetType: targetType,
			SysSet: $(this).hasClass("favorite-systoggle"),
		},
	}).then(function (data) {
		const favs = $(".favorite[data-targetid='" + targetId + "'][data-targettype='" + targetType + "']");
		favs.removeClass("favorite-notset favorite-set favorite-sysset");
		favs.addClass(data.Object.FavoriteCssClass);
		elem.trigger("favoriteChanged", [targetId, targetType, data.Object.FavoriteCssClass]);
	});
});