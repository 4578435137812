import $ from 'jquery';
import imagesLoaded from 'imagesloaded';
import Wookmark from 'wookmark';
import { ResizeWatcher } from '/js/utility/resize-watcher';
import { isVisible } from '/js/utility/is-visible';
import Delay from "/js/utility/delay";

//? It is possible that we can use a MutationObserver to automatically tile things when the child list changes?
//? That would mean we would never need to worry about calling tiles()

/**
 * @typedef TilesSettings
 * @property {string} [align] Alignment, default 'left'
 * @property {boolean} [autoResize] Auto-update the layout when the browser window is resized, default true.
 * @property {number} [offset] The distance between grid items, default 0.
 * @property {number} [outerOffset] The distance to the containers border, default 0.
 * @property {JQuery} [container] Used for some extra CSS styling
 * @property {string} [flexibleWidth] Flexible width of a grid item
 * @property {string} [itemWidth] The width of a grid item
 * @property {Function} [onLayoutChanged] Some callback
 */

/**
 * @type TilesSettings
 */
const settings = {
	align: 'left',
	autoResize: true, // autoresize ourselves instead
	offset: 0,
	outerOffset: 0,
};

function getSettings(t, opts) {
	const width = t.hasClass("tiles-50") ? "50%" : t.hasClass("tiles-25") ? "25%" : "33.33333333%";
	return $.extend({
		// we need to set BOTH itemWidth and flexibleWidth for it to work, flexible makes the height recalculate on resize
		itemWidth: width,
		flexibleWidth: width,
	}, settings, opts);
}

/**
 * Use Wookmark to tile the element
 * @param {HTMLElement|JQuery|string} elem HTMLElement, JQuery object or selector of the parent element of the individual tiles
 * @param {TilesSettings} [opts] Options
 */
export function tiles(elem, opts) {
	const t = $(elem);
	const options = getSettings(t, opts);

	imagesLoaded(t, function () {
		// TODO: Constructing wookie like this means adding multiple onResize handlers etc... we should keep track of these - essentially make this module something you register ONCE
		new Wookmark(t.get(0), options);
	});

	// TODO: Add resize event handler outside of Wookmark.autoResize
	// TODO: Still think we should make this a class that we construct for each place we want to use Tiles. <----
}

export function detile(elem) {
	// remove inline styles set by wookmark
	const t = $(elem);
	t.children().each(function () {
		// reset tiling
		this.style.position = "";
		this.style.width = "";
		this.style.top = "";
		this.style.left = "";
	});
	t.get(0).style.height = "";
}

export default class Tiles
{
	/**
	 * Create a new Tiles object.
	 * @param {HTMLElement|JQuery} elem
	 * @param {Function} onresize function to check whether tiles.refresh should be called on resize
	 */
	constructor(elem, onresize) {
		this.elem = $(elem);
		imagesLoaded(this.elem, () => {
			this.wookmark = new Wookmark(this.elem.get(0), getSettings(this.elem, { autoResize: false }));

			this.onresize = typeof onresize === 'function'
				? () => onresize(elem)
				: () => true;

			this.resizeWatcher = new ResizeWatcher(this.elem.get(0), () => this.refresh());
		});
	}

	/** Refreshes the tiles. */
	refresh() {
		if (this.wookmark && isVisible(this.elem.get(0)) && this.onresize()) {
			// because wookmark is retarded and keeps track of its children, we call initItems to reset the list EVERY TIME
			//debounce.then(() => this.layout());
			this.layout();
		}
	}

	layout() {
		this.wookmark.initItems();
		this.wookmark.layout(true); // pass true for "force"
	}

	/** Removes styling from tiles. */
	clear() {
		// not sure this is needed, but it would let us stop wookmark from resizing automatically.
		detile(this.elem);
		// we can't really clear wookmark, cause we can't get it going again - which is why we'd rather resize manually
		//this.wookmark.clear();
	}
}