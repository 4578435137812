import $ from 'jquery';

// General script for modules

$(document).on('click', '.module-section-switch a', function (e) {
	const that = $(this),
		module = that.closest('.module'),
		hash = this.href.substring(this.href.indexOf('#'));

	if (hash.length > 1) {
		e.preventDefault();
		that.closest('.module-actions').find('.module-section-switch.is-active').removeClass('is-active');
		that.closest('li').addClass('is-active');

		module.find('.module-section').removeClass('is-active');
		$(hash).addClass('is-active');
	}
});
