import Delay from './delay';

/** Simplified ResizeObserver with callback and delay of 50 */
export class ResizeWatcher
{
	constructor(element, callback, wait) {
		this.delay = new Delay(wait ?? 50);
		this.observer = new ResizeObserver(entries => {
			for (let entry of entries) {
				this.delay.then(() => callback(entry));
			}
		});
		this.observer.observe(element);
	}
}

/** promise-like watchResize function  */
export function watchResize(element) {
	let resolve = () => {};
	const thenable = {
		then: (callback) => resolve = callback,
	};
	
	new ResizeObserver(entries => {
		for (let entry of entries) {
			resolve(entry);
		}
	}).observe(element)
	
	return thenable;
}