import $ from 'jquery';

/**
 * Takes a jquery form and serializes the form data into an object
 * @param {JQuery<HTMLElement>} $form The form to serialize
 */
export default function serializeObject($form) {
	const o = {};
	const a = $form.serializeArray();
	$.each(a, function () {
		if (o[this.name] !== undefined) {
			if (!o[this.name].push) {
				o[this.name] = [o[this.name]];
			}
			o[this.name].push(this.value || '');
		} else {
			o[this.name] = this.value || '';
		}
	});
	return o;
}