import $ from 'jquery';
require("velocity-animate");

$(document).on('click', '.search-advanced-toggle', function (e) {
	e.preventDefault();
	const module = $(this).closest('.search');

	if (module.hasClass('is-advanced')) {
		module.find('.search-advanced').velocity('slideUp', {
			duration: 300,
			complete: function () {
				module.removeClass('is-advanced');
			}
		});
	} else {
		module.find('.search-advanced').velocity('slideDown', {
			duration: 300,
			complete: function () {
				module.addClass('is-advanced');
			}
		});
	}

});
