import $ from 'jquery';
import "/modules/reveal-modal";

// TODO: would be nice to have "overloads"/optional argument according to below

// .alert(title [, message] [, callback])
/**
Create an alert dialog
@param {string} title the title
@param {string} message the message
@returns {Promise} Promise resolved when user clicks ok
*/
export function alertDialog(title, message) {
	return showdialog("#dialog_alert", title, message, null);
}

// .confirm(title [, message] [, callback])
/**
Create a confirm dialog
@param {string} title the title
@param {string} message the message
@returns {Promise} Promise resolved when user clicks ok
*/
export function confirmDialog(title, message) {
	return showdialog("#dialog_confirm", title, message, null);
}

// .confirm(title [, message] [, defaultTextBoxValue] [, callback])
/**
Create a prompt dialog
@param {string} title the title
@param {string} message the message
@param {string} defaultTextBoxValue the default value for the textbox
@returns {Promise<string>} Promise resolved with the text box value when user clicks ok
*/
export function promptDialog(title, message, defaultTextBoxValue) {
	return showdialog("#dialog_prompt", title, message, defaultTextBoxValue);
}

function showdialog(dialog, title, message, defaultTextBoxValue) {

	return new Promise((resolve) => {
		const $t = $(dialog);
		$t.find(".dialog_title").text(title);
		$t.find(".dialog_message").text(message || "");
		$t.find("form").on("submit.dialog", function (e) {
			e.preventDefault();
			hidedialog($t);
			resolve($t.find('input').val());
		});
		$t.find(".dialog_cancel").on("click.dialog", function (e) {
			e.preventDefault();
			hidedialog($t);
		});
		$t.reveal({ animation: "none", closeOnBackgroundClick: false }); // have to disable animation here, because the next reveal() will not know about the last one...
		$t.find('input[type=text]').val(defaultTextBoxValue || "");
		$t.find('input').first().trigger("focus");
	});

}

function hidedialog($t) {
	$t.find("form").off("submit.dialog");
	$t.find(".dialog_cancel").off("click.dialog");
	$t.trigger("reveal:close");
}
