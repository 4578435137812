import $ from 'jquery';

if ($(".pulldownmenu").length) {

	$(document).on("scroll", function (e) {
		const x = $(".pulldownmenu");
		if ($(this).scrollTop() > $(x.data("reference-element")).offset().top - 60) {
			x.addClass("visible");
		}
		else {
			x.removeClass("visible");
		}
	});

}
