import $ from 'jquery';

// Notify the user that items has been added to the order basket
export function AddToCart(amount) {
	const textNotification = $(".cart .notification"),
		basket = $(".cart");

	setTimeout(function () {
		const oldAmount = parseInt(textNotification.text().trim()),
			newAmount = Math.max(oldAmount + amount, 0);

		textNotification.text(newAmount);
		basket.toggleClass("cart-isempty", newAmount === 0);
	}, amount > 0 ? 0 : 300);

	// Flash basket if items added
	if (amount > 0) {
		basket.addClass("newItemAnimation");
		setTimeout(function () {
			basket.removeClass("newItemAnimation");
		}, 1200);
	}
	else if (amount < 0) {
		basket.addClass("removeItemAnimation");
		setTimeout(function () {
			basket.removeClass("removeItemAnimation");
		}, 600);
	}
}

export function OpenItemAddedDialog() {
	$("#product-added-to-basket-modal").reveal();
}