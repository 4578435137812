import $ from 'jquery';
import { createConfirm } from '../confirm';
import "select2";
import { refreshTemplate, getHtmlFromTemplate } from "../handlebars";

// Clicked a list-item
$(document).on('click', '.multi-list-box-item > a', function (e) {
	e.preventDefault();
	const that = $(this);
	that.closest('.multi-list-box-list').find('a.is-active').removeClass('is-active');

	activate(that);

	if (that.attr('href').length) {
		window.location.href = that.attr('href');
	}
});

// Clicked the remove-icon
$(document).on('click', '.multi-list-box-item-delete', function (e) {
	e.preventDefault();

	const that = $(this);
	const module = that.closest('.multi-list-box');
	const item = that.closest('.multi-list-box-item').find('a:first');

	// Show confirmation-dialog
	createConfirm(that)
		.then(() => {
			const jsonToTrigger = { Id: item.attr('data-id'), Title: item.find('.multi-list-box-item-label').text() };

			// Add it to the select 2
			const selectToUpdate = module.find('.multi-list-box-select');
			selectToUpdate.append($(`<option value="${jsonToTrigger.Id}">${jsonToTrigger.Title}</option>`));
			selectToUpdate.trigger("change");

			// Remove it from the list
			const activateOtherItem = item.hasClass('is-active');
			that.closest('.multi-list-box-item').remove();

			// Trigger event
			module.trigger("itemRemoved", jsonToTrigger);

			// If the removed item was selected, select another one
			if (activateOtherItem) {
				const firstItem = module.find('li:first > a');
				if (firstItem.length) {
					activate(firstItem);
				}
			}
		});
});

// Activate an item
function activate(item) {
	item.addClass('is-active');

	// Trigger event
	const json = { Id: item.attr('data-id'), Title: item.find('.multi-list-box-item-label').text() };
	item.closest('.multi-list-box').trigger("itemSelected", json);
}

// Call to initiate the select 2 component
export function initMultiListBoxSelect(selector) {
	const select = $('#' + selector + ' .multi-list-box .multi-list-box-select');

	select.select2({
		width: "style",
		placeholder: select.data("placeholder"),

		sorter: function (values) {
			function sortByName(a, b) {
				const aName = a.text.toLowerCase();
				const bName = b.text.toLowerCase();
				return ((aName < bName) ? -1 : ((aName > bName) ? 1 : 0));
			}
			return values.sort(sortByName);
		}
	});

	// Trigger when something is selected in the select 2 component
	select.on("select2:select", function (e) {
		// Remove the item from the select 2
		select.find(`option[value=${e.params.data.id}]`).remove();
		select.trigger("change");

		// Add the item to the list
		const list = $('#' + selector + ' .multi-list-box .multi-list-box-list');
		const json = { Id: e.params.data.id, Title: e.params.data.text, AddedToList: true };
		list.append(getHtmlFromTemplate("multi-list-box-item-template", json));

		// Trigger event
		list.closest('.multi-list-box').trigger("itemAdded", json);
	});
}

// Refresh the data of an already initated box
export function refreshMultiListBox(selector, data) {
	const select = $(selector).closest('.multi-list-box').find('.multi-list-box-select');

	refreshTemplate("#multi-list-box-item-template", selector, "", data.Values);

	select.empty();
	select.append($(`<option>`));
	$.each(data.Values, function (i, jsonItem) {
		if (!jsonItem.AddedToList && !jsonItem.Fixed) {
			select.append($(`<option value="${jsonItem.Id}">${jsonItem.Title}</option>`));
		}
	});
	select.trigger("change");
}
