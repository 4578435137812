import $ from 'jquery';
import "qtip2";

// you can store a json string like data-qtipopts='{"position":{"my":"top right","at":"bottom center"}}'

// or use the simplified version:
// data-qtip-position="left|center|right"

$('.qtip-me').each(function () {
	const t = $(this),
		pos = t.data("qtipPosition"),
		options = {};

	// read options from data
	if (pos) {
		switch (t.data("qtipPosition")) {
			case "left":
				options.position = { my: "top right", at: "bottom center" };
				break;
			case "center":
				options.position = { my: "top center", at: "bottom center" };
				break;
			case "right":
				options.position = { my: "top left", at: "bottom center" };
				break;
		}
	}

	t.qtip(options);
});
