import $ from 'jquery';

$(document).on('click', '.expandable-list-item-header', function (e) {
	const t = $(this);
	e.preventDefault();

	const body = t.parent().find('.expandable-list-item-body');
	if (body.is(":visible")) {
		body.slideUp("fast");
		t.removeClass("is-active");
	}
	else {
		body.slideDown("fast");
		t.addClass("is-active");
	}
});
