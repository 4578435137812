import $ from 'jquery';
require("imagesloaded");
import { createConfirm } from '../confirm';
import { ajax } from "/js/utility/ajax";
import { AddToCart } from '../cart';

// todo: maybe this should 1) not be in modules, 2) be required from respective page scripts that use it? it's too specific...

// Initialize all baskets
$('.basket-table').each(function () {
	const basketTable = $(this),
		basketCheckboxes = $('.basket-checkbox', basketTable);
	let numberOfCheckedCheckboxes = 0;

	// Add the .is-checked class to each <tr> where the checkbox is checked
	basketCheckboxes.each(function () {
		const checkbox = $(this);
		if (checkbox.is(':checked')) {
			checkbox.closest('tr').addClass('is-selected');
			numberOfCheckedCheckboxes++;
		}
	});

	// if 50% or more of the checkboxes are checked, then also check the toggle checkbox (in the header)
	if (numberOfCheckedCheckboxes >= (basketCheckboxes.length / 2)) {
		$('thead .basket-checkbox', basketTable).attr('checked', true);
	}

	basketTable.imagesLoaded(function () {
		$('.basket-preview', basketTable).each(function () {
			const elem = $(this),
				img = $('img', elem);

			if (img.height() < elem.height()) {
				img.css({
					'margin-top': (elem.height() - img.height()) / 2
				});
			}

		});
	});
});



//----------------------------------------------------------------------------------
//		Event handlers
//----------------------------------------------------------------------------------

// Checkbox toggle is clicked
$(document).on('change', 'thead .basket-checkbox', function () {
	const toggle = $(this),
		isChecked = toggle.is(':checked'),
		checkboxes = toggle.closest('.basket-table').find('tbody .basket-checkbox');

	// note: This construction is neccesary to make sure that the change event is triggered 
	//			properly on each checkbox
	checkboxes.each(function () {
		const checkbox = $(this);
		if (checkbox.is(':checked') !== isChecked) {
			checkbox.trigger('click');
		}
	});
});

// Checkbox in row changed
$(document).on('change', 'tbody .basket-checkbox', function () {
	const checkbox = $(this),
		isChecked = checkbox.is(':checked'),
		tr = checkbox.closest('tr'),
		id = tr.data('id');

	checkbox.closest('tr').toggleClass('is-selected', isChecked);

	if (isChecked) {
		checkbox.closest('.basket').trigger('basket.itemSelected', [tr, id]);
	} else {
		checkbox.closest('.basket').trigger('basket.itemDeselected', [tr, id]);
	}

});

// Clicked preview
$(document).on('click', '.basket-preview', function (e) {
	e.preventDefault();
	const that = $(this),
		id = that.closest('tr').data('id'),
		json = eval("[" + that.closest('tr').attr("data-orderJson") + "]");

	that.closest('.basket').trigger('basket.itemPreview', [id, json]);
});

// Changed supplier
$(document).on('change', '.basket-supplier', function (e) {
	e.preventDefault();
	const that = $(this),
		supplierId = that.val(),
		id = that.closest('tr').data('id');

	that.closest('.basket').trigger('basket.supplierChanged', [id, supplierId]);
});

// Changed Quantity
$(document).on('change', '.basket-quantity', function (e) {
	e.preventDefault();
	const that = $(this),
		quantity = that.val(),
		id = that.closest('tr').data('id');

	that.closest('.basket').trigger('basket.quantityChanged', [id, quantity]);
});

// Click on delete button
$(document).on('click', '.basket-delete', function (e) {
	e.preventDefault();

	const that = $(this),
		tr = that.closest('tr'),
		id = tr.data('id');

	createConfirm(that)
		.then(() => that.closest('.basket').trigger('basket.itemDelete', [tr, id]));
});

//! This doesn't even belong to basket - it's in order history
$(document).on('click', '.basket-reorderbutton', function (e) {
	e.preventDefault();
	ajax({
		url: $(this).data('url'),
	}).then(() => AddToCart(1));
});
