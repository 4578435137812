import $ from 'jquery';
import { setNoScroll, releaseNoScroll } from '../no-scroll';

//! This is fine and all, but there's really no point in calling createConfirm, is there?
//! There should be a special class that can be applied to buttons (confirm-button? or something)
//! that does this automatically and throws some "confirm" event that we can use rather than "click".
//! That will create issues though, because we'd need to add it to the document and there's a risk the click event will be caught by something else before...


/**
 * Creates a confirm thingy with the title attribute of the element as confirm text.
 * @param {HTMLElement|JQuery} element Element for which to create a confirm
 * @param {string} [text] Option confirm text. If omitted, text is read from the title attribute of the element. 
 * @returns Promise A promise that resolved when/if the user confirms
 */
export function createConfirm(element, text) {
	return new Promise(function (resolve) {
		const that = $(element),
			label = text || that.attr('title'),
			confirm = createConfirmHtml(label),
			pos = that.get(0).getBoundingClientRect(),
			offsetLeft = pos.left + (pos.width / 2),
			offsetTop = pos.bottom + 5,
			confirmBg = $('<div class="confirm-background"></div>');

		confirmBg.append(confirm);
		$('body').append(confirmBg);
		setNoScroll();

		const left = offsetLeft - (confirm.outerWidth() / 2);
		confirm.css({
			top: offsetTop,
			left: left,
		});

		$(document).one('click', function () {
			confirmBg.remove();
			releaseNoScroll();
		});

		confirm.on('click', function (e) {
			e.preventDefault();
			e.stopPropagation();

			confirmBg.remove();
			releaseNoScroll();
			resolve();
		});
	});
}

function createConfirmHtml(label) {
	return $('<div class="confirm"> \
					<a href="" class="confirm-delete"> \
						<i class="confirm-caret"></i> \
						' + label + ' \
					</a> \
				</div>');
}

$(document).on("click", "[data-confirm]", function (e) {
	const t = $(this);

	if (!t.data("confirmed")) {
		e.preventDefault();
		createConfirm(t, t.data("confirm"))
			.then(() => {
				t.data("confirmed", true);
				t.click();
			});
	} else {
		t.removeData("confirmed");
	}
})